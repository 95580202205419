import {reset} from 'redux-form';
import * as actionTypes from '../actions/branch';

const axios = require('axios');

const initialState = {
    branches: [],
    branch: null,
    warehouses: []
};

export default function branch(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_BRANCHES:
            return {
                ...state,
                branches: action.payload
            };
        case actionTypes.SET_BRANCH:
            return {
                ...state,
                branch: action.payload
            };
        case actionTypes.SET_WAREHOUSES:
            return {
                ...state,
                warehouses: action.payload
            };
        case actionTypes.LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case actionTypes.MESSAGE:
            return {
                ...state,
                message: action.payload
            };
        default:
            return state;
    }
};

export const listBranches = () => (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE, payload: {} });
    axios.get('/branches/')
      .then(function (response) {
        dispatch({ type: actionTypes.SET_BRANCHES, payload: response.data });
      });
};

export const listWarehouses = () => (dispatch) => {
    axios.get('/warehouses/')
      .then(function (response) {
        dispatch({ type: actionTypes.SET_WAREHOUSES, payload: response.data });
      });
};


export const createBranch = data => (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE, payload: {} });
    dispatch({ type: actionTypes.LOADING, payload: true });
    
    axios.post('/branches/', data)
      .then(function (response) {
        dispatch({ type: actionTypes.LOADING, payload: false });
        if(response.status == 201) {
            dispatch({ type: actionTypes.MESSAGE, payload: {status: response.status, type: 'success', text: null, data: response.data} });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.LOADING, payload: false });

        try {
            dispatch({ type: actionTypes.MESSAGE, payload: {status: null, type: 'danger', text: error.response.data.non_field_errors[0]} });
        } catch(err) {}
        
        try {
            let errors = error.response.data;
            let message = [];
            for(var item in errors) {
                errors[item].forEach((text, key) => {
                    message.push({
                        type: 'danger',
                        text: text
                    })
                })
            }
            dispatch({ type: actionTypes.MESSAGE, payload: message });
        } catch(err) {}
      });
};

export const deleteBranch = (id, callback) => (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE, payload: {} });
    dispatch({ type: actionTypes.LOADING, payload: true });
    
    axios.delete(`/branches/${id}/`)
      .then(function (response) {
        dispatch({ type: actionTypes.LOADING, payload: false });
        callback()
      });
};

export const editBranch = (id, data) => (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE, payload: {} });
    dispatch({ type: actionTypes.LOADING, payload: true });
    axios.put(`/branches/${id}/`, data)
      .then(function (response) {
        dispatch({ type: actionTypes.LOADING, payload: false });
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.LOADING, payload: false });

        try {
            dispatch({ type: actionTypes.MESSAGE, payload: {type: 'danger', text: error.response.data.non_field_errors[0]} });
        } catch(err) {}
        
        try {
            let errors = error.response.data;
            let message = [];
            for(var item in errors) {
                errors[item].forEach((text, key) => {
                    message.push({
                        type: 'danger',
                        text: text
                    })
                })
            }
            dispatch({ type: actionTypes.MESSAGE, payload: message });
        } catch(err) {}
      });
};

export const setBranch = (id) => (dispatch) => {
    axios.get(`/branches/${id}/`)
      .then(function (response) {
        dispatch({ type: actionTypes.SET_BRANCH, payload: response.data });
      });
};

export const resetBranch = (id) => (dispatch) => {
    dispatch({ type: actionTypes.SET_BRANCH, payload: null });
    dispatch(reset('branch'));
};