export const LOADING = "order/LOADING";
export const MESSAGE = "order/MESSAGE";
export const SET_ORDERS = "order/SET_ORDERS";
export const SET_ORDER = "order/SET_ORDER";
export const SET_LAST_UPDATE = "order/SET_LAST_UPDATE";
export const UPDATE_ORDERS = "order/UPDATE_ORDERS";
export const UPDATE_ORDER = "order/UPDATE_ORDER";
export const DELETE_ORDER = "order/DELETE_ORDER";
export const SET_HAS_MORE = "order/SET_HAS_MORE";
export const SET_PAGE = "order/SET_PAGE";
export const SET_START_DATE = "order/SET_START_DATE";
export const SET_END_DATE = "order/SET_END_DATE";
export const CLEAR_ORDERS = "order/CLEAR_ORDERS";
export const SET_STATUS_PENDING = "order/SET_STATUS_PENDING";
export const SET_STATUS_INPROGRESS = "order/SET_STATUS_INPROGRESS";
export const SET_STATUS_COLLECTED = "order/SET_STATUS_COLLECTED";
export const SET_STATUS_FINALIZED = "order/SET_STATUS_FINALIZED";
export const SET_STATUS = "order/SET_STATUS";
export const SET_BRANCH = "order/SET_BRANCH";
export const SET_MESSAGE = "order/SET_MESSAGE";
export const TOGGLE_MODAL_USER = "animations/TOGGLE_MODAL_USER";
export const TOGGLE_STATUS_BUTTON_CHARGING = "animations/TOGGLE_STATUS_BUTTON_CHARGING";
