/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { FiHome, FiShoppingBag } from 'react-icons/fi';
import { distanceBetween } from 'geofire-common';
const { compose } = require('recompose');
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} = require('react-google-maps');

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)((props) => (
  <>
    <GoogleMap
      defaultZoom={5}
      defaultCenter={{ lat: props.middle[0], lng: props.middle[1] }}
    >
      <Marker
        position={{ lat: props.origin[0], lng: props.origin[1] }}
        /* icon={{
        url: "https://www.flaticon.es/svg/vstatic/svg/1930/1930196.svg?token=exp=1611196149~hmac=9028d0d16af74ea790b2c6d69bdb9a06",
        scaledSize:  new google.maps.Size(40,40)
      }}  */
      />

      <Marker
        position={{ lat: props.destination[0], lng: props.destination[1] }}
        /* icon={{
        url: "https://www.flaticon.es/svg/vstatic/svg/1670/1670965.svg?token=exp=1611196571~hmac=df6d667d4b3dd6364b430094408ec60d",
        scaledSize:  new google.maps.Size(40,40)
      }}  */
      />
    </GoogleMap>
  </>
));

export default function Mapa({ origin, destination }) {
  function middlePoint(lat1, lng1, lat2, lng2) {
    function toRad(number) {
      return (number * Math.PI) / 180;
    }

    function toDeg(number) {
      return number * (180 / Math.PI);
    }

    //-- Longitude difference
    var dLng = toRad(lng2 - lng1);

    //-- Convert to radians
    lat1 = toRad(lat1);
    lat2 = toRad(lat2);
    lng1 = toRad(lng1);

    var bX = Math.cos(lat2) * Math.cos(dLng);
    var bY = Math.cos(lat2) * Math.sin(dLng);
    var lat3 = Math.atan2(
      Math.sin(lat1) + Math.sin(lat2),
      Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY)
    );
    var lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX);

    //-- Return result
    return [toDeg(lat3), toDeg(lng3)];
  }

  useEffect(() => {}, [origin, destination]);
  return (
    <div>
      {origin && destination ? (
        <MapWithAMarker
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD9dRjmypZk90YzbHXIAUFyKxBJ_dcHj0I&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          origin={origin}
          destination={destination}
          middle={middlePoint(
            origin[0],
            origin[1],
            destination[0],
            destination[1]
          )}
        />
      ) : null}
    </div>
  );
}
