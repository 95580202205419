import * as actionTypes from '../actions/signin';

const axios = require('axios');

const initialState = {
    isLogged: false,
    isLoading: false,
    message: {},
    user: null
};

export default function signin(state = initialState, action) {
    switch (action.type) {
        case actionTypes.LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case actionTypes.MESSAGE:
            return {
                ...state,
                message: action.payload
            };
        case actionTypes.LOGGED:
            return {
                ...state,
                isLogged: action.payload
            };
        default:
            return state;
    }
};

export const login = data => (dispatch) => {
    dispatch({ type: actionTypes.MESSAGE, payload: {} });
    dispatch({ type: actionTypes.LOADING, payload: true });
    axios.post('/login/', data)
      .then(function (response) {
        localStorage.token = response.data.token;
        localStorage.user = JSON.stringify(response.data.user)
        dispatch({ type: actionTypes.LOGGED, payload: true });
        dispatch({ type: actionTypes.LOADING, payload: false });
        window.location.reload();
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.LOADING, payload: false });

        try {
            dispatch({ type: actionTypes.MESSAGE, payload: {type: 'danger', text: error.response.data.non_field_errors[0]} });
        } catch(err) {}
      });
};