import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import layout from "./reducers/layout";
import signin from "./reducers/signin";
import user from "./reducers/user";
import order from "./reducers/order";
import branch from "./reducers/branch";
import carrier from "./reducers/carrier";

const rootReducer = combineReducers({
  layout,
  signin,
  user,
  order,
  branch,
  carrier,
  form: formReducer,
});

export default rootReducer;
