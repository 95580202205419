import React, { useEffect } from "react";
import firebase from "firebase/app";
import "./styleTracking.css";
import Mapa from "./Mapa";
import axios from "axios";
import logoWhatsapp from "./whatsapp.png";
const geofire = require("geofire");
require("firebase/auth");
require("firebase/database");

export default function Tracking() {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: "AIzaSyAyq9eO4h8JUilJW_P59W7XZ6SryemL_Po",
      authDomain: "farmaciasdelpueblo.firebaseapp.com",
      projectId: "farmaciasdelpueblo",
      storageBucket: "farmaciasdelpueblo.appspot.com",
      messagingSenderId: "484123832391",
      appId: "1:484123832391:web:52f531d2601bf7a02e8162",
      measurementId: "G-1NMT19XC6C",
    });
  } else {
    firebase.app(); // if already initialized, use that one
  }
  const changeID = (e) => {
    if (e == "") {
      setId(e);
    }
    setId(e);
  };

  const repeatFuntion = () => {
    setInterval(() => {
      if (datosRepartidor != null) {
        geoFireInstance.get(datosRepartidor.id).then(
          function (location) {
            if (location === null) {
              setDataPosicion(null);
              console.log("Provided key is not in GeoFire");
              setMessage(
                "Todavía no se inicio el recorrido o el ID es invalido"
              );
            } else {
              setDataPosicion(location);
              console.log("Provided key has a location of " + location);
            }
          },
          function (error) {
            console.log("Error: " + error);
          }
        );
      }
    }, 10000);
  };

  const checkLocal = () => {
    const URLactual = window.location;
    let URL = null;
    if (URLactual.toString().includes("localhost")) {
      URL = "http://localhost:8000";
    } else {
      URL = "https://delpueblofarmacias.com.ar/";
    }
    return URL;
  };

  const checkKeyFirebase = () => {
    if (id != null) {
      axios.get(`${checkLocal()}/v1/tracking/?id=${id}`).then((resp) => {
        const repartidor = resp.data.data.user;
        const origin = resp.data.location;
        const idGeofire = resp.data.data.user.idRepartidor;
        console.log("Status", resp.data.data.status);
        setStatusOrden(resp.data.data.status);
        console.log("Data resp", resp.data);
        geoFireInstance.get(idGeofire.toString()).then(
          function (location) {
            if (location === null) {
              setDataPosicion(null);
              console.log("Provided key is not in GeoFire");
              setMessage("Aun no encontramos datos para este ID");
            } else {
              setOrigin(origin.reverse());
              repeatFuntion();
              setDatosRepartidor({
                nombre: repartidor.first_name,
                apellido: repartidor.last_name,
                telefono: repartidor.phone,
                id: idGeofire,
                whatsapp: repartidor.whatsapp,
              });
              setDataPosicion(location);
              console.log("Provided key has a location of " + location);
            }
          },
          function (error) {
            console.log("Error: " + error);
          }
        );
      });
    }
  };

  const firebaseRef = firebase.database().ref();
  const geoFireInstance = new geofire.GeoFire(firebaseRef);
  const [id, setId] = React.useState(null);
  const [dataPosicion, setDataPosicion] = React.useState(null);
  const [timeOut, setTimeOutBoolean] = React.useState(null);
  const [origin, setOrigin] = React.useState(null);
  const [datosRepartidor, setDatosRepartidor] = React.useState(null);
  const [message, setMessage] = React.useState("Esperando codigo...");
  const [statusOrden, setStatusOrden] = React.useState(null);
  useEffect(() => {
    const idQuery = window.location.search.slice(4).toString();
    if (idQuery != undefined) {
      changeID(idQuery);
    }
  }, [dataPosicion, datosRepartidor, id, statusOrden]);
  return (
    <div>
      <header>Farmacias del pueblo</header>
      <div className="mainContainer">
        <div className="containerLeft">
          <h2>Bienvenido</h2>
          <h3>Ingrese su codigo de tracking</h3>
          <input
            className="inputContainer"
            value={id}
            onChange={(e) => {
              changeID(e.target.value);
            }}
          ></input>
          <button
            className="buttonEnviar"
            onClick={() => {
              checkKeyFirebase();
            }}
          >
            Enviar
          </button>
        </div>
        <div className="containerRight">
          {statusOrden == "in-progress" ? (
            <h2>Todavia no se inició el recorrido</h2>
          ) : null}
          {statusOrden == "collected" ? (
            <>
              {console.log("Collected")}
              {dataPosicion && datosRepartidor ? (
                <>
                  <Mapa origin={origin} destination={dataPosicion} />
                  <h4>Datos del repartidor</h4>
                  {datosRepartidor.nombre ? (
                    <h5>Nombre: {datosRepartidor.nombre}</h5>
                  ) : null}
                  {datosRepartidor.apellido ? (
                    <h5>Apellido: {datosRepartidor.apellido}</h5>
                  ) : null}
                  {console.log("repartidor", datosRepartidor)}
                  {datosRepartidor.telefono && datosRepartidor.whatsapp ? (
                    <a
                      href={`https://api.whatsapp.com/send?phone=${datosRepartidor.telefono}`}
                      target="_blank"
                    >
                      <img className="iconoWhatsapp" src={logoWhatsapp}></img>
                    </a>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
          {statusOrden == "finalized" ? (
            <h2>El pedido ya fue entregado</h2>
          ) : null}
        </div>
      </div>
    </div>
  );
}

// Al momento de asignar debe tener un mapa con todos los puntos.

// Cadeteria -> Cadete

// puede pasar a retirar por cualquiera de las tiendas.

// N pedidos a N sucursales.

// Enviar aviso cuando el repartidor haga click en retirar, antes no.

// Optimizar por distancia.
